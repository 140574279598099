import React from "react"
import type { UtilityButtonProps } from "v2/react/shared/TableUtilities/UtilityButtons"
import { EditTableSettingsButton } from "v2/react/shared/TableUtilities/shared/EditTableSettingsButton"

const Button = ({ active, onUtilityToggle }: UtilityButtonProps) => (
  <EditTableSettingsButton
    active={active}
    onClick={() => onUtilityToggle("edit-position-report-table-settings")}
  />
)

export { Button }
