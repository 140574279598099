import React, { FormEvent } from "react"
import cn from "classnames"
import { useTranslation } from "react-i18next"

interface Props {
  show: boolean
  disableSubmit?: boolean
  onCancel: () => void
  onSave?: (e: FormEvent) => void
}

function Footer({ show, disableSubmit, onCancel, onSave }: Props) {
  const { t } = useTranslation()

  const handleCancel = () => {
    onCancel()
  }

  return (
    <div className={cn("drawer-section-footer", { hidden: !show })}>
      <div className="drawer-section-footer-buttons gap-2 p-2">
        <button type="button" className="btn--sm btn--secondary" onClick={handleCancel}>
          {t("v2.orgchart.super_panel.clear_changes")}
        </button>
        <button
          type="submit"
          onClick={onSave}
          className={cn("btn--sm btn--primary", { disabled: disableSubmit })}
          disabled={disableSubmit}
        >
          {t("v2.orgchart.super_panel.apply")}
        </button>
      </div>
    </div>
  )
}

export { Footer }
