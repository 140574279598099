import React from "react"
import type { Args, Meta, StoryObj } from "@storybook/react"

import { AccordionModulePanel } from "./AccordionModulePanel"

const meta: Meta<typeof AccordionModulePanel> = {
  title: "Shared/AccordionModulePanel",
  component: AccordionModulePanel,
  decorators: [
    (Story) => (
      <div
        style={{
          maxWidth: "35rem",
          margin: "2rem auto",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Story />
        <Story />
      </div>
    ),
  ],
}

export default meta
type Story = StoryObj<typeof AccordionModulePanel>

const children = (
  <>
    <p>
      Nebulae are made up of gas ― primarily hydrogen and helium ― and fine cosmic dust. These
      clouds are part of the interstellar medium of extremely low-density gas and dust that exists
      between stars in the void of space, material so dispersed through the vastness of the cosmos
      that it can have a density as low as 0.1 atoms per cubic centimeter.
    </p>
    <p>
      In contrast, a cubic centimeter of the air we breathe on Earth would contain about 10 million
      trillion molecules.
    </p>
  </>
)

export const Main: Story = {
  args: {
    title: "Nebulae are made up of gas",
    children,
  },
  render: ({ title, children }: Args) => (
    <AccordionModulePanel title={title} headerContent={title}>
      {children}
    </AccordionModulePanel>
  ),
}

export const LongText: Story = {
  args: {
    title:
      "A famous example of an emission nebula is the Orion Nebula, a huge, star-forming nebula in the constellation Orion. The Orion Nebula is home to a star cluster defined by four massive stars known as the Trapezium.",
    children,
  },
  render: ({ title, children }: Args) => (
    <AccordionModulePanel title={title} headerContent={title}>
      {children}
    </AccordionModulePanel>
  ),
}
