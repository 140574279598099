import React, { useState } from "react"
import cn from "classnames"
import { isEqual, omit } from "lodash"

import { useTranslation } from "react-i18next"
import { useFieldsetWithSearch } from "v2/react/shared/FieldsetWithSearch/hooks/useFieldsetWithSearch"
import { UnappliedChangesModal } from "v2/react/shared/TableUtilities/UnappliedChangesModal"

import type { Option, PositionReportTableSettingsQuery } from "types/graphql"
import type { Table } from "v2/react/shared/TableUtilities"
import { PositionReportsWithSettings } from "types/graphql.enums"

import { FieldsetWithSearch } from "v2/react/shared/FieldsetWithSearch"
import { Footer } from "v2/react/shared/Sidebar/Footer"

interface Props {
  table: Table
  allFields: Option[]
  initialTableSettings: DeepNonNullable<
    PositionReportTableSettingsQuery,
    "tableSettings"
  >["currentPerson"]["settings"]["positionTableSettings"]["tableSettings"]
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const PanelContent = ({ table, allFields, initialTableSettings, onSubmit }: Props) => {
  const { t } = useTranslation()
  const initialAdditionalSettings = omit(initialTableSettings, ["selectedColumns", "__typename"])
  const [additionalSettings, setAdditionalSettings] = useState(initialAdditionalSettings)

  const {
    selectedFields,
    unselectedFields,
    formKey,
    handleReorder,
    handleSearchSelect,
    handleFieldCheckboxChange,
    fieldsChanged,
    resetState,
  } = useFieldsetWithSearch({
    selectedFieldKeys: initialTableSettings.selectedColumns,
    allFields,
  })

  const showFooter = fieldsChanged || !isEqual(additionalSettings, initialAdditionalSettings)
  UnappliedChangesModal.useSetUnappliedChanges(showFooter)

  const handleCancel = () => {
    resetState()
    setAdditionalSettings(initialAdditionalSettings)
  }

  const handleAdditionalSettingsChange = (key: string, value: boolean) => {
    setAdditionalSettings({
      ...additionalSettings,
      [key]: value,
    })
  }

  return (
    <form onSubmit={onSubmit} key={formKey} className={cn("h-full", { "!pb-10": showFooter })}>
      <div className="h-full overflow-y-scroll p-3">
        {table === PositionReportsWithSettings.OpenPositions && (
          <label htmlFor="include_scheduled_terminations" className="checkbox mb-6 select-none">
            <input
              checked={!!additionalSettings.includeScheduledTerminations}
              id="include_scheduled_terminations"
              name="include_scheduled_terminations"
              type="checkbox"
              onChange={(e) =>
                handleAdditionalSettingsChange("includeScheduledTerminations", e.target.checked)
              }
            />
            {t("v2.person_settings.edit_positions_page_settings.include_scheduled_terminations")}
          </label>
        )}

        <div className="input-group">
          <FieldsetWithSearch<Option>
            id="positions-report-table-data-options"
            description={t("v2.person_settings.edit_positions_page_settings.columns_to_display")}
            selectedItems={selectedFields}
            searchableItems={unselectedFields}
            onReorder={handleReorder}
            onSearchSelect={handleSearchSelect}
            onCheckboxChange={handleFieldCheckboxChange}
          />
        </div>
        <div className="mb-1 font-bold">
          {t(
            "v2.person_settings.edit_positions_page_settings.what_to_include_in_span_of_control_totals",
          )}
        </div>
        <label htmlFor="count_open_positions" className="checkbox mb-2 select-none">
          <input
            checked={additionalSettings.countOpenPositions}
            id="count_open_positions"
            name="count_open_positions"
            type="checkbox"
            onChange={(e) => handleAdditionalSettingsChange("countOpenPositions", e.target.checked)}
          />
          {t("v2.person_settings.edit_positions_page_settings.count_open_positions")}
        </label>
        <label htmlFor="count_dotted_relationships" className="checkbox select-none">
          <input
            checked={additionalSettings.countDottedRelationships}
            id="count_dotted_relationships"
            name="count_dotted_relationships"
            type="checkbox"
            onChange={(e) =>
              handleAdditionalSettingsChange("countDottedRelationships", e.target.checked)
            }
          />
          {t("v2.person_settings.edit_positions_page_settings.count_dotted_relationships")}
        </label>
      </div>
      <Footer show={showFooter} onCancel={handleCancel} />
    </form>
  )
}

export { PanelContent }
