import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Props {
  passwordValue: string
}

function PasswordDisplayToggle({ passwordValue }: Props) {
  const [valueIsMasked, setValueIsMasked] = useState(true)
  const maskedValue = "••••••••••"
  const toggleValueIsMasked = () => setValueIsMasked(!valueIsMasked)

  return (
    <pre>
      {valueIsMasked ? maskedValue : passwordValue}

      <button
        type="button"
        className="ml-4 cursor-pointer bg-transparent"
        onClick={toggleValueIsMasked}
        aria-label={valueIsMasked ? "show_value".t("defaults") : "hide_value".t("defaults")}
      >
        <FontAwesomeIcon icon={["far", valueIsMasked ? "eye" : "eye-slash"]} />
      </button>
    </pre>
  )
}

export default PasswordDisplayToggle
