import React from "react"
import type { Meta, StoryObj } from "@storybook/react"

import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltip"

const meta: Meta<typeof Tooltip> = {
  component: Tooltip,
  argTypes: {},
  decorators: [
    (Story) => (
      <div style={{ margin: 60 }}>
        <Story />
      </div>
    ),
  ],
  // This component will have an automatically generated Autodocs entry: https://storybook.js.org/docs/7.0/react/writing-docs/docs-page
  tags: ["autodocs"],
  title: "Shared/Tooltip",
}

const ContentStyle = Object.freeze({ fontFamily: "Open Sans" })

const TriggerStyle = Object.freeze({
  alignItems: "center",
  borderRadius: "0.1875rem",
  display: "inline-flex",
  fontFamily: "Open Sans",
  height: "2.4375rem",
  padding: "0 1rem",
})

type Story = StoryObj<typeof Tooltip>
const BasicUsage: Story = {
  args: {},
  render: () => (
    <Tooltip>
      <TooltipTrigger style={TriggerStyle}>Hover over me to reveal a tooltip.</TooltipTrigger>
      <TooltipContent style={ContentStyle}>
        Content that reveals itself with a flourish.
      </TooltipContent>
    </Tooltip>
  ),
}

const InitiallyOpen: Story = {
  args: { initialOpen: true },
  render: ({ initialOpen }) => (
    <Tooltip initialOpen={initialOpen}>
      <TooltipTrigger style={TriggerStyle}>Important content</TooltipTrigger>
      <TooltipContent style={ContentStyle}>
        Content to show immediately, but can hide after the user hovers over the trigger.
      </TooltipContent>
    </Tooltip>
  ),
}

const Popover: Story = {
  args: { open: true, placement: "top" },
  render: ({ open, placement }) => (
    <Tooltip open={open} placement={placement}>
      <TooltipTrigger style={TriggerStyle}>Important content</TooltipTrigger>
      <TooltipContent style={ContentStyle}>
        Augmented with important notes that should always show.
      </TooltipContent>
    </Tooltip>
  ),
}

export default meta
export { BasicUsage, InitiallyOpen, Popover }
