import React from "react"

import { PositionReportsWithSettings } from "types/graphql.enums"
import type { Utility, Table } from "v2/react/shared/TableUtilities"

import { Sidebar } from "v2/react/shared/Sidebar"
import { EditPositionReportSettings } from "v2/react/shared/TableUtilities/EditPositionReportSettings"
import { FilterPositionReport } from "v2/react/shared/TableUtilities/FilterPositionReport"
import { EditSuccessionTableSettings } from "v2/react/shared/TableUtilities/EditSuccessionTableSettings"
import { EditAssetsTableSettings } from "v2/react/shared/TableUtilities/EditAssetsTableSettings"
import { EditPositionTypesTableSettings } from "v2/react/shared/TableUtilities/EditPositionTypesTableSettings"

interface UtilityPanelProps {
  table: Table
  open: boolean
  selectedUtility: Utility
  onUtilityToggle: (utility: Utility) => void
  onAfterClose?: () => void
}

type PanelProps = Pick<UtilityPanelProps, "table" | "onUtilityToggle">

const UtilityPanel = ({
  table,
  open,
  selectedUtility,
  onUtilityToggle,
  onAfterClose,
}: UtilityPanelProps) => (
  <Sidebar open={open} onAfterClose={onAfterClose}>
    {selectedUtility === "edit-position-report-table-settings" && isPositionTable(table) && (
      <EditPositionReportSettings.Panel table={table} onUtilityToggle={onUtilityToggle} />
    )}
    {selectedUtility === "filter-position-report" && isPositionTable(table) && (
      <FilterPositionReport.Panel table={table} onUtilityToggle={onUtilityToggle} />
    )}
    {selectedUtility === "edit-succession-table-settings" && table === "succession-plans" && (
      <EditSuccessionTableSettings.Panel onUtilityToggle={onUtilityToggle} />
    )}
    {selectedUtility === "edit-assets-table-settings" && table === "assets" && (
      <EditAssetsTableSettings.Panel onUtilityToggle={onUtilityToggle} />
    )}
    {selectedUtility === "edit-position-types-table-settings" && table === "position-types" && (
      <EditPositionTypesTableSettings.Panel onUtilityToggle={onUtilityToggle} />
    )}
  </Sidebar>
)

const getPjaxContainerForTable = (table: PositionReportsWithSettings) => {
  switch (table) {
    case PositionReportsWithSettings.PositionsPage:
      return "[data-pjax-container='positions-list']"
    default:
      return "[data-pjax-container='position_reports_table']"
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isPositionTable = (table: any): table is PositionReportsWithSettings =>
  Object.values(PositionReportsWithSettings).includes(table)

export { UtilityPanel, getPjaxContainerForTable }
export type { PanelProps }
