import { useMemo } from "react"
import { GqlQueryError } from "v2/graphqlClient/types"

type MutationErrors = GqlQueryError | undefined | null

const useAllMutationErrors = (rootErrors: MutationErrors, inlineErrors: MutationErrors) =>
  useMemo(() => {
    const all: { text: string }[] = []

    eachErrorIn(rootErrors, (message) => all.push({ text: message }))
    eachErrorIn(inlineErrors, (message) => all.push({ text: message }))

    return all
  }, [inlineErrors, rootErrors])

const eachErrorIn = (error: MutationErrors, handleFn: (message: string) => void) => {
  if (Array.isArray(error)) error.map(({ message }) => handleFn(message))
  else if (error?.message) handleFn(error.message)
}

export { useAllMutationErrors }
