import type { Meta, StoryObj } from "@storybook/react"
import React from "react"
import { OrderListItemStatic } from "./OrderListItemStatic"

const meta: Meta<typeof OrderListItemStatic> = {
  title: "Shared/OrderListItemStatic",
  component: OrderListItemStatic,
  decorators: [
    (Story) => (
      <div style={{ margin: "20px 25%" }}>
        <Story />
      </div>
    ),
  ],
}

export default meta

type Story = StoryObj<typeof OrderListItemStatic>

export const Main: Story = {
  args: {
    indexPanelContent: (
      <div>
        <p>One</p>
      </div>
    ),
    indexPanelColor: "primary-light",
    mainPanelContent: (
      <div>
        <p className="font-bold">Leslie Knope</p>
        <p>Why would anybody ever eat anything besides breakfast food?</p>
      </div>
    ),
  },
}
