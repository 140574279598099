import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"
import { FieldType } from "../types"

interface Props {
  showStyle: boolean
  fieldType: FieldType
  errored: boolean
}

function SelectLayer({ showStyle, fieldType, errored }: Props) {
  return (
    <AnimatePresence>
      {showStyle && (
        <motion.div
          key="border"
          variants={opaqueBasic}
          animate="shown"
          exit="hidden"
          className={classNames("GridBody-cell__selected border border-solid", {
            "border-status-critical": errored,
            "border-primary-100": !errored,
          })}
        >
          {fieldType === FieldType.SelectDropdown && (
            <motion.div
              key="caret"
              variants={opaqueBasic}
              animate="shown"
              exit="hidden"
              className="GridBody-cell__caret"
            >
              <FontAwesomeIcon
                className={classNames({
                  "text-status-critical": errored,
                  "text-primary-100": !errored,
                })}
                icon={["fas", "caret-down"]}
              />
            </motion.div>
          )}
          <motion.div
            key="handle"
            variants={opaqueBasic}
            animate="shown"
            exit="hidden"
            className={classNames("GridBody-cell__selected-handle", {
              "bg-status-critical": errored,
              "bg-primary-100": !errored,
            })}
            style={{
              bottom: 0,
              right: 0,
              transform: "translate(50%, 50%)",
            }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export { SelectLayer }
