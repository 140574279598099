import React from "react"
import { createRoot } from "react-dom/client"

import { Invitations } from "v2/react/components/headcountPlanning/Invitations"
import { StoreProvider } from "v2/redux/StoreProvider"

$.onmount("#react-headcount-plans-invitations-container", () => {
  const container = document.getElementById("react-headcount-plans-invitations-container")
  const root = createRoot(container)
  const { headcountPlanId, currentCompanyId, currentPersonId } = container.dataset

  root.render(
    <StoreProvider>
      <Invitations
        headcountPlanId={headcountPlanId}
        companyId={currentCompanyId}
        currentPersonId={currentPersonId}
      />
    </StoreProvider>,
  )
})
