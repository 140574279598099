import React from "react"
import { createRoot } from "react-dom/client"
import { Invitations } from "v2/react/components/headcountPlanning/Invitations"
import { StoreProvider } from "v2/redux/StoreProvider"

$.onmount("#react-headcount-plans-invitations__container", () => {
  const container = document.getElementById("react-headcount-plans-invitations__container")
  const root = createRoot(container)
  root.render(
    <StoreProvider>
      <Invitations companyId={gon.current_company_id} currentPersonId={gon.current_person_id} />
    </StoreProvider>,
  )
})
