import React, { useState } from "react"

import RootProvider from "v2/react/components/RootProvider"
import { UnappliedChangesModal } from "v2/react/shared/TableUtilities/UnappliedChangesModal"

import { UtilityButtons } from "v2/react/shared/TableUtilities/UtilityButtons"
import { UtilityPanel } from "v2/react/shared/TableUtilities/UtilityPanel"

import type { PositionReportsWithSettings } from "types/graphql.enums"

type Utility =
  | null
  | "edit-position-report-table-settings"
  | "filter-position-report"
  | "edit-succession-table-settings"
  | "edit-assets-table-settings"
  | "edit-position-types-table-settings"

type Table = PositionReportsWithSettings | "succession-plans" | "assets" | "position-types"

interface TableUtilitiesProps {
  table: Table
}

type HandleToggleAction = (action: "close" | "switch" | "open", utility: Utility) => void

const TableUtilitiesContent = ({ table }: TableUtilitiesProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [selectedUtility, setSelectedUtility] = useState<Utility>(null)
  const { unappliedChanges, hideModal, showModal } = UnappliedChangesModal.useContext()

  const handleToggleAction: HandleToggleAction = (action, utility) => {
    if (action === "close") {
      // When closing the utility panel, we reset the selected utility in
      // onAfterClose in order to prevent the content from clearing before the
      // panel is fully closed.
      setSidebarOpen(false)
    } else if (action === "open") {
      setSelectedUtility(utility)
      setSidebarOpen(true)
    } else if (action === "switch") {
      setSelectedUtility(utility)
    }
  }

  const handleToggleActionWithConfirm: HandleToggleAction = (action, utility) => {
    if (!unappliedChanges) {
      handleToggleAction(action, utility)
      return
    }

    const onConfirm = () => {
      handleToggleAction(action, utility)
      hideModal()
    }

    showModal({
      onConfirm,
    })
  }

  const handleUtilityToggle = (utility: Utility) => {
    if (sidebarOpen && (!utility || selectedUtility === utility)) {
      handleToggleActionWithConfirm("close", utility)
    } else if (sidebarOpen && selectedUtility !== utility) {
      handleToggleActionWithConfirm("switch", utility)
    } else if (!sidebarOpen && utility) {
      handleToggleAction("open", utility)
    }
  }

  const handleOnAfterPanelClose = () => setSelectedUtility(null)

  return (
    <>
      <UtilityButtons
        table={table}
        // We pass null through as soon as the sidebar is closed so that the
        // state of the button transitions immediately to the inactive state.
        selectedUtility={sidebarOpen ? selectedUtility : null}
        onUtilityToggle={handleUtilityToggle}
      />
      <UtilityPanel
        table={table}
        open={sidebarOpen}
        selectedUtility={selectedUtility}
        onUtilityToggle={handleUtilityToggle}
        onAfterClose={handleOnAfterPanelClose}
      />
    </>
  )
}

const TableUtilities = ({ table }: TableUtilitiesProps) => (
  <RootProvider>
    <UnappliedChangesModal.Provider>
      <TableUtilitiesContent table={table} />
    </UnappliedChangesModal.Provider>
  </RootProvider>
)

export default TableUtilities
export type { Table, Utility, TableUtilitiesProps }
