import React, { useMemo, useState } from "react"

import { useTranslation } from "react-i18next"
import { useFetchCollectionsQuery } from "v2/redux/GraphqlApi"

import { buildCollectionsQueryFromKeys } from "v2/redux/slices/GridSlice/gridHelpers/collections"
import { getQueryParams, UrlHelper } from "v2/react/utils/urls"

import {
  getPjaxContainerForTable,
  type PanelProps as UtilityPanelProps,
} from "v2/react/shared/TableUtilities/UtilityPanel"
import type { Collections } from "types/graphql"

import { PanelContent } from "v2/react/shared/TableUtilities/FilterPositionReport/PanelContent"
import { Sidebar } from "v2/react/shared/Sidebar"
import { pjaxSubmit } from "v2/react/utils/pjax"
import { PositionReportsWithSettings } from "types/graphql.enums"

const FILTER_KEYS: (keyof Collections)[] = [
  "chartSections",
  "locations",
  "employeeTypes",
  "jobFamilies",
  "positionImportances",
  "orgUnitTypes",
]

type Filters = Record<string, string>

interface PanelProps {
  table: PositionReportsWithSettings
  onUtilityToggle: UtilityPanelProps["onUtilityToggle"]
}

const Panel = ({ table, onUtilityToggle }: PanelProps) => {
  const { t } = useTranslation()

  const [initialFilters, setInitialFilters] = useState(getFilterValuesFromQueryParams())
  const [filters, setFilters] = useState(initialFilters)

  const { data, isFetching, isError } = useFetchCollectionsQuery(
    buildCollectionsQueryFromKeys(FILTER_KEYS),
  )

  const collections = useMemo(() => {
    if (!data) return undefined
    return data.filter((d) => d.options?.nodes.length > 0)
  }, [data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    const field = e.target.id
    setFilters((prev) => ({ ...prev, [field]: value }))
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    const container = getPjaxContainerForTable(table)

    pjaxSubmit({
      event: e,
      url: UrlHelper.positionReportPath(table),
      container,
      fragment: container,
      replace: true,
    })
    setInitialFilters(filters)
  }

  const dataLoaded = !isFetching && !isError && collections && collections.length > 0

  return (
    <Sidebar.Content
      title={t("v2.defaults.filter")}
      isError={isError}
      isLoading={!dataLoaded}
      onClose={() => onUtilityToggle(null)}
    >
      {dataLoaded && (
        <PanelContent
          collections={collections}
          filters={filters}
          initialFilters={initialFilters}
          handleFilterChange={handleFilterChange}
          onCancel={() => setFilters(initialFilters)}
          onSubmit={handleSubmit}
        />
      )}
    </Sidebar.Content>
  )
}

const getFilterValuesFromQueryParams = () =>
  Object.entries(getQueryParams()).reduce((acc, [key, value]) => {
    const innerKeyMatch = key.match(/\[([^\]]+)\]/)
    if (innerKeyMatch) {
      const innerKey = innerKeyMatch[1]
      acc[innerKey] = value[0]
    }
    return acc
  }, {} as Filters)

export { Panel }
export type { Filters }
