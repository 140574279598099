import React from "react"
import type { Meta, StoryObj } from "@storybook/react"

import { Dropdown } from "./Dropdown"

const meta: Meta<typeof Dropdown> = {
  title: "Shared/Dropdown",
  component: Dropdown,
  decorators: [
    (story, context) => (
      <div className="bg-slate-100 items-center justify-center p-10 flex">
        <div style={{ width: "300px" }}>{story({ ...context, args: { ...context.args } })}</div>
      </div>
    ),
  ],
}

export default meta
type Story = StoryObj<typeof Dropdown>

const DropdownContents = () => (
  <>
    <Dropdown.Trigger>Click me</Dropdown.Trigger>
    <Dropdown.Menu>
      <Dropdown.Item> Something </Dropdown.Item>
      <Dropdown.Item> Something else </Dropdown.Item>
    </Dropdown.Menu>
  </>
)

export const Basic: Story = {
  args: {
    children: DropdownContents(),
    align: "left",
  },
}
