import React from "react"
import type { Args, Meta, StoryObj } from "@storybook/react"
import { DateRange } from "v2/react/shared/DateRange/DateRange"
import { parseDate } from "@internationalized/date"
import RootProvider from "v2/react/components/RootProvider"
import dayjs from "dayjs"

const meta: Meta<typeof DateRange> = {
  title: "Shared/DateRange",
  component: DateRange,
  decorators: [
    (Story) => (
      <RootProvider>
        <div style={{}}>
          <Story />
        </div>
      </RootProvider>
    ),
  ],
}

export default meta
type Story = StoryObj<typeof DateRange>

export const DateRangeUncontrolled: Story = {
  args: {
    label: "Date Range",
    errorMessage: "Oops wrong date!",
  },
  render: ({ label, errorMessage }: Args) => (
    <DateRange label={label} errorMessage={errorMessage} className="w-[280px]" />
  ),
}

const DateRangeWithValue = () => {
  const [date, setDate] = React.useState({
    start: parseDate(dayjs().subtract(1, "year").format("YYYY-MM-DD")),
    end: parseDate(dayjs().format("YYYY-MM-DD")),
  })

  return (
    <DateRange
      label="Date Range"
      className="w-[280px]"
      value={date}
      onChange={setDate}
      maxValue={parseDate(dayjs().format("YYYY-MM-DD"))}
    />
  )
}

export const DateRangeControlled: Story = {
  render: () => DateRangeWithValue(),
}
