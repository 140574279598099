import { Meta } from "@storybook/react"
import PasswordDisplayToggle from "./PasswordDisplayToggle"

export default {
  title: "Shared/PasswordDisplayToggle",
  component: PasswordDisplayToggle,
  argTypes: {
    passwordValue: {
      control: "text",
      description: "The actual password value to be displayed when toggled.",
    },
  },
  tags: ["autodocs"],
} as Meta

export const Main = {
  args: {
    passwordValue: "secret",
  },
}
