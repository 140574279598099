import cn from "classnames"
import { forEach } from "lodash"
import React from "react"
import { Modal } from "./Modal"

interface Props {
  children: React.ReactNode
  isOpen: boolean
  isLoading?: boolean
  onClose: (event: React.MouseEvent) => void
  title: string
  onSubmit: (event: React.FormEvent) => void
}

const ModalForm = ({ children, isOpen, isLoading, onClose, title, onSubmit }: Props) => {
  const onSave = (e: React.FormEvent) => {
    e.preventDefault()
    // TODO: Define this as FormEvent<CustomForm> for the implementation in
    // webpack/v2/react/components/headcountPlanning/PositionModal.tsx
    // See: https://claritydev.net/blog/typescript-typing-form-events-in-react
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const input: any = {}

    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formKeys = Array.from(formData.keys())

    forEach(formKeys, (attr) => {
      input[attr] = form[attr].value
    })

    onSubmit(input)
  }

  return (
    <Modal title={title} onClose={onClose} isOpen={isOpen}>
      <form onSubmit={onSave}>
        <div className="react-modal__body">
          <div className="mb-4 cursor-default">{children}</div>
        </div>

        <div className="react-modal__footer">
          <button
            type="submit"
            className={cn("btn--large btn--primary", { "btn-disabled": isLoading })}
          >
            {isLoading && <div className="spinner" />}
            {"Save".t("default")}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export { ModalForm }
