import { Meta } from "@storybook/react"
import { AlertBanner } from "./AlertBanner"

export default {
  title: "Shared/AlertBanner",
  component: AlertBanner,
  argTypes: {
    type: {
      options: ["error", "info", "neutral", "warning", "success"],
    },
  },
} as Meta

export const Main = {
  args: {
    messages: ["An error occurred while processing your request."],
    type: "error",
  },
}
